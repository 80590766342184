import React from 'react';
import { Box, Flex, Hide, Text } from '@chakra-ui/react';
import Ads from '../../../components/Ads';
import Logo from '../../../components/Logo';
import Back from '../../../components/Back';
import Link from 'next/link';

export interface AuthLayoutProps {
  heading?: string;
  subheading?: string;
  children: React.ReactNode;
}

const AuthLayout = ({ heading, children }: AuthLayoutProps) => {
  return (
    <Box display="flex" flexDirection={{ lg: 'row' }} minH="100vh">
      <Flex
        w={{ base: '100%', md: '60%' }}
        bgImage="/images/bg.png"
        bgSize="cover"
        bgPos="center"
        px={3}
        py={12}
        flexDir={['column', 'row']}
      >
        <Box alignSelf="flex-start">
          <Back />
        </Box>
        <Box
          w="100%"
          display="flex"
          alignItems={'center'}
          flexDirection="column"
          gap={12}
          py={{ base: 10, lg: 0 }}
          px={{ md: 10 }}
        >
          <Flex
            my={8}
            w={['90%', '55%']}
            mx="auto"
            maxW="lg"
            flexDirection="column"
            justifyContent="right"
            justifySelf={{ lg: 'center' }}
            gap={14}
          >
            <Link href="/">
              <Logo justifyContent="right" />
            </Link>

            <Box my={{ md: 14 }}>
              <Text
                color="blue"
                fontSize="26px"
                fontWeight="500"
                my={2}
                fontFamily="heading"
              >
                {heading}
              </Text>
              {children}
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Hide below="md">
        <Flex w={{ md: '40%' }}>
          <Ads
            bgGradient={
              process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG'
                ? 'linear(180deg, #FFE7A7 0%, #FFE7A7 100%)'
                : 'linear(180deg, #0F61D6 0%, #3E2CB1 100%)'
            }
            w="100%"
          />
        </Flex>
      </Hide>
    </Box>
  );
};

export default AuthLayout;
