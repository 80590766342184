import { Box, Button, Flex, Input, Spinner } from '@chakra-ui/react';
import {
  CountrySelector,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getValidPhoneByPartner, isUK } from '../utils/partnerTheme';

import useValidatePhone from '../hooks/validate-phone-on-debounce';

interface ChakraPhoneProps {
  value: string;
  onChange: (phone: string) => void;
  disableBtn: Dispatch<SetStateAction<boolean>>;
}

const PhoneInput: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
  disableBtn,
}) => {
  const {
    debounceOnChange,
    isPhoneInputFocus,
    setIsPhoneInputFocus,
    isPhoneValid,
    setIsPhoneValid,
    isCheckingPhoneValid,
  } = useValidatePhone();

  const [phone, setPhone] = useState(value);
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return [...(isUK ? ['gb'] : ['ng'])].includes(iso2);
  });

  const phoneInput = usePhoneInput({
    defaultCountry: isUK ? 'gb' : 'ng',
    value: phone,
    onChange: (data) => {
      setPhone(data.phone);
      onChange(data.phone);
    },
    countries,
  });

  const handlePhoneValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const countryCode = phoneInput.country.dialCode;
    if (!e.target.value.startsWith(`+${countryCode}`)) {
      setPhone(`+${countryCode}${e.target.value}`);
    } else {
      setPhone(e.target.value);
    }
  };

  useEffect(() => {
    if (isPhoneValid !== true) {
      disableBtn(true);
    } else {
      disableBtn(false);
    }
  }, [disableBtn, isPhoneValid]);

  useEffect(() => {
    if (value) {
      debounceOnChange(value, disableBtn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex align="center" pos="relative">
        <CountrySelector
          selectedCountry={phoneInput.country.iso2}
          onSelect={(country) => phoneInput.setCountry(country.iso2)}
          renderButtonWrapper={({ children, rootProps }) => (
            <Button {...rootProps} variant="outline" p={6} mr="8px">
              {children}
            </Button>
          )}
          countries={countries}
        />
        <Input
          pos="relative"
          placeholder="Enter Phone number"
          type="tel"
          value={phone}
          //   onChange={handlePhoneValueChange}
          p={6}
          ref={phoneInput.inputRef}
          onChange={(e) => {
            handlePhoneValueChange(e);
            debounceOnChange(e.target.value, disableBtn);
            setIsPhoneValid('typing');
          }}
          onBlur={() => setIsPhoneInputFocus(false)}
          onFocus={() => setIsPhoneInputFocus(true)}
        />
        <Flex align="center" pos="absolute" right="5" height="full" zIndex={1}>
          {isPhoneInputFocus &&
            (isPhoneValid === 'typing' || isCheckingPhoneValid ? (
              <Spinner size="sm" />
            ) : isPhoneValid ? (
              <MdCheckCircle />
            ) : (
              <MdCancel />
            ))}
        </Flex>
      </Flex>
      {!isPhoneValid && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          px={2}
          py={1}
          bg="red.100"
        >
          {String(isPhoneValid) === 'typing' || isCheckingPhoneValid ? (
            <Spinner size="sm" />
          ) : isPhoneValid ? (
            <MdCheckCircle />
          ) : (
            <MdCancel />
          )}{' '}
          {getValidPhoneByPartner()}
        </Box>
      )}
    </>
  );
};

export default PhoneInput;
