import React from 'react';
import MetaHead from '../components/meta-head';
import LoginForm from '../features/authentication/forms/LoginForm';
import AuthLayout from '../features/authentication/layout/AuthLayout';

function login() {
  return (
    <div>
      <MetaHead
        title="Login to Your Account - PicknFix"
        description="Access your PicknFix account to manage your device insurance, repair requests, and protection plans. Secure and easy login process."
      />
      <AuthLayout heading="Hello Again!">
        <LoginForm />
      </AuthLayout>
    </div>
  );
}

export async function getStaticProps() {
  return { props: {} };
}

export default login;
