import { Box, Flex, Image, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { useRouter } from 'next/router';

const Ads = (props: BoxProps) => {
  const router = useRouter();
  const { asPath } = router;
  return (
    <Box {...props}>
      <Flex
        flexDirection="column"
        w="85%"
        mx="auto"
        py={8}
        gap={16}
        alignItems="center"
      >
        {process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG' ? (
          <a href="https://www.mtn.ng/" rel="noreferrer" target="_blank">
            <Image
              src={
                process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG'
                  ? '/images/playlist-ad.svg'
                  : '/images/deliver1.png'
              }
              alt="delivery"
              maxH="420px"
              maxW="420px"
              w="100%"
            />
          </a>
        ) : (
          <Image
            src={
              process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG'
                ? '/images/playlist-ad.svg'
                : '/images/deliver1.png'
            }
            alt="delivery"
            maxH="420px"
            maxW="420px"
            w="100%"
          />
        )}

        <Image
          src={
            process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG' &&
            !asPath.includes('/account')
              ? '/images/trust-us.svg'
              : '/images/fix.png'
          }
          alt="delivery"
          maxH="420px"
          maxW="420px"
          w="100%"
        />
      </Flex>
    </Box>
  );
};

export default Ads;
