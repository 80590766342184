/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Flex, FlexProps, Box } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

import { getLogoByPartner, getTitleByPartner } from '../utils/partnerTheme';

function Logo(props: FlexProps) {
  return (
    //@ts-ignore
    <Flex {...props} w="100%" justifyContent="center">
      {/* w={{ base: '95px', sm: '100%' }} */}
      <Box>
        <Image
          width={200}
          src={getLogoByPartner()}
          alt={`${getTitleByPartner()} Logo`}
        />
      </Box>
    </Flex>
  );
}

export default Logo;
